"use strict";

 angular.module('postCenter.environment', [])

.constant('BASE_URL', 'https://apistage.adere.so')

.constant('GA', 'G-LWFQLH0XFH')

.constant('FB_APP_ID', '845052388872761')

.constant('FB_API_VERSION', 'v18.0')

.constant('RECAPTCHA_CHECKBOX_TOKEN', '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI')

.constant('RECAPTCHA_INVISIBLE_TOKEN', '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI')

.constant('OLARK_TOKEN', '6791-867-10-4388')

.constant('INTERCOM_TOKEN', 'rji3ea4w')

.constant('SENTRY_DSN', 'https://f5f94ccccabcd338f81ea2e10d025da8@sentry.adere.so/5')

.constant('GOOGLE_API_KEY', 'AIzaSyDc2qSlFOX6lSMTHES8b75A5SpjxgrexW0')

.constant('TIEMZONE_DB_KEY', 'OVA16C2VQZP8')

.constant('SLACK_CLIENT_ID', '5014892154.314060371398')

.constant('TALKCENTER_TOKEN', 'bcded7c45fef108c4ab0fb5992a74163')

.constant('FACEBOOK_PERMISSIONS', 'pages_read_engagement::pages_manage_metadata::pages_messaging::instagram_basic::instagram_manage_messages::instagram_manage_comments::pages_read_user_content::pages_manage_engagement::pages_manage_posts::business_management::pages_show_list')

.constant('FRONTEND3_URL', '/frontend3/#/')

.constant('FRONTEND_VUE_URL', '/vue/#')

.constant('ENV', 'stage')

.constant('FIREBASE', 'eyJhcGlLZXkiOiJBSXphU3lDbGN1WUgyQUpjSWVLbE8xc0VEMDIta21tTTBJZE03NmsiLCJhdXRoRG9tYWluIjoib21lZ2EtbWF0aC02MTAuZmlyZWJhc2VhcHAuY29tIiwiZGF0YWJhc2VVUkwiOiJodHRwczovL29tZWdhLW1hdGgtNjEwLmZpcmViYXNlaW8uY29tIiwicHJvamVjdElkIjoib21lZ2EtbWF0aC02MTAiLCJzdG9yYWdlQnVja2V0Ijoib21lZ2EtbWF0aC02MTAuYXBwc3BvdC5jb20iLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjU4NDU3NzUzNjgyMyIsImFwcElkIjoiMTo1ODQ1Nzc1MzY4MjM6d2ViOmExNjJhMmQ2NTEyODY1ZDFhYzAwYjIiLCJtZWFzdXJlbWVudElkIjoiRy1QS0pMQlRES0g4In0=')

.constant('WOOTRIC_TOKEN', 'NPS-1294ea21')

.constant('SSO_LOGIN', '')

.constant('DESK_AV', 'https://desk-av-dev.adere.so')

;